import { useAuthContext } from "../../context/AuthContext";
import { useMutation, gql } from "@apollo/client";
import { useRouter } from "next/router";
import { loginDataFields } from "../../query/login";
import { useForm } from "@mantine/form";
import { Button, PasswordInput, TextInput } from "@mantine/core";

const LOGIN_MUTATION = gql`
  ${loginDataFields}
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      ...LoginDataFields
    }
  }
`;

export default function LoginForm() {
  const router = useRouter();
  const { setModalState, handleLogin } = useAuthContext();
  const [login, { loading }] = useMutation(LOGIN_MUTATION);

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },
    validate: {
      email: (value) =>
        /^\S+@\S+$/.test(value) ? null : "L'adresse email n'est pas valide",
      password: (value) =>
        value.length < 2 ? "Ce champ est obligatoire" : null,
    },
  });

  function handleSubmit(model: typeof form.values) {
    const { password } = model;
    // Trim all white space from the email input
    const email = model.email.replace(/\s/g, "");
    login({ variables: { email, password } })
      .then(({ data }) => {
        const { login } = data;
        if (login?.user && login?.token) {
          const role = login.user.role?.name || "";
          handleLogin(login.token, login.user);
          let url = "/user/my-application";
          if (role === "administrator") url = "/admin/applications";
          else if (role === "jury") url = "/jury/answers";
          else if (role === "jury-president") url = "/jury-president/answers";
          router.push(url);
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          console.error(error);
        }
        // Handel backend errors
        form.setErrors({ password: "Mot de passe erroné" });
      });
  }

  return (
    <form
      onSubmit={form.onSubmit((model) => handleSubmit(model))}
      className="w-full flex flex-col gap-3"
    >
      <TextInput
        label="Email"
        name="email"
        type="email"
        withAsterisk
        disabled={loading}
        autoComplete="email"
        {...form.getInputProps("email")}
      />
      <PasswordInput
        label="Mot de passe"
        name="password"
        withAsterisk
        disabled={loading}
        autoComplete="current-password"
        className="w-full"
        {...form.getInputProps("password")}
      />
      <div className="w-full flex flex-row gap-2 mt-2 justify-between items-center">
        <Button type="submit" loading={loading}>
          Se connecter
        </Button>
        <a
          onClick={() => setModalState("forget-password")}
          href="#"
          className="block hover:underline text-sm"
        >
          Mot de passe oublié ?
        </a>
      </div>
    </form>
  );
}
