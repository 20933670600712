import "../styles/globals.scss";
import Layout from "../src/layout";
import { ReactElement, ReactNode, useEffect } from "react";
import { event, pageView } from "../src/lib/gtag";
import { NextPage } from "next";
import { AppProps, NextWebVitalsMetric } from "next/app";
import { navigation } from "../src/config/navigation";
import { AuthContextProvider } from "src/context/AuthContext";
import { ApolloProvider } from "@apollo/client";
import client from "../src/lib/apollo-client";

function renderDefaultLayout(
  page: ReactElement,
  pageProps: Record<string, unknown>
): ReactNode {
  return <Layout pageProps={pageProps}>{page}</Layout>;
}

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export function reportWebVitals(metric: NextWebVitalsMetric) {
  const { id, name, label, value } = metric;
  event({
    label: id,
    action: name,
    category: label === "web-vital" ? "Web Vitals" : "Next.js custom metric",
    // values must be integers
    value: Math.round(name === "CLS" ? value * 1000 : value),
    // avoids affecting bounce rate.
    nonInteraction: true,
  });
}

function MyApp({ Component, pageProps, router }: AppPropsWithLayout) {
  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      const _url = url.toString();
      const route = navigation.find((item) => item.url === _url);
      pageView(url, route?.label || "");
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => router.events.off("routeChangeComplete", handleRouteChange);
  }, [router.events]);

  const wrapWithLayout = Component.getLayout || renderDefaultLayout;

  return (
    <ApolloProvider client={client}>
      <AuthContextProvider>
        {wrapWithLayout(<Component {...pageProps} />, pageProps)}
      </AuthContextProvider>
    </ApolloProvider>
  );
}

export default MyApp;
