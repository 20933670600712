const config = {
  separator: "|",
  title: "CJD Business Awards",
  description:
    "Concours organisé depuis 2008 par le CJD Tunisie pour récompenser des jeunes dirigeants tunisiens ayant fait preuve de leadership.",
  keywords: "CJD Tunisie, CJD, CJD Business Awards, Tunisie, leadership",
  smtp: {
    port: 587,
    host: "smtp.ethereal.email",
    auth: {
      user: "adah.crooks91@ethereal.email",
      pass: "yWbQJesE9TFBJYvkFN",
    },
  },
  fromEmail: "Tekru Technoloiges <noreply@tekru.net>",
  toEmails: {
    contact: "kharrat.mohamed@gmail.com",
    joinForm: "kharrat.mohamed@gmail.com",
  },
  headerColor: "#dc2626",
  GA_TrackingId: "G-YC5HF6D5JD",
  hotjarId: 3159127,
  googleSiteVerification: "Mr2TVAyHzDKptYzrV62diLkBKAkBk4cXLdQOXLXbfj0",
};

export default config;
