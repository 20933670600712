import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const httpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_GQL_API_URL,
});

const authLink = setContext((_, context) => {
  // Get the token from the client side
  let token = null;
  if (typeof window !== "undefined") {
    token = cookies.get("tkr_auth_token") || null;
  }
  // return the headers to the context so httpLink can read them
  if (!token) return context;
  return {
    ...context,
    headers: {
      ...(context.headers || {}),
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  ssrMode: true,
});

export default client;
