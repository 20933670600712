import { useState, useEffect } from "react";
import NavItem from "./NavItem";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import classes from "./Nav.module.scss";
import clsx from "clsx";
import Logo from "../Logo";
import { mainMenu, MenuItem } from "../../config/navigation";
import Button from "../Button/Button";

function Nav() {
  let list: MenuItem[] = [];
  if (Array.isArray(mainMenu)) {
    list = mainMenu;
  }
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(!open);

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if ((event.keyCode || event.key) === 27) setOpen(false);
    };
    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  return (
    <>
      <div className={classes["menu-icon"]} onClick={handleClick}>
        <i className={clsx({ open })}>
          <AiOutlineMenu size="2rem" />
        </i>
      </div>
      <nav
        className={clsx(classes["nav-menu"], {
          active: open,
        })}
      >
        <div
          className={clsx(classes["mobile-nav-menu-header"], { active: open })}
          onClick={handleClick}
        >
          <div className={classes["menu-icon"]}>
            <Logo width={302} height={73} />
          </div>
          <div className={classes["menu-icon"]}>
            <AiOutlineClose />
          </div>
        </div>
        {list.map((item) => (
          <NavItem key={item.id} item={item} setClick={setOpen} />
        ))}
        <div className="block md:hidden pl-8 pt-2">
          <Button href="/#sign-up" className="p-6 text-center">
            <span className="font-light text-xs leading-0">
              Tentez votre chance <br />
              <span className="font-extrabold text-base">Soumissionnez</span>
            </span>
          </Button>
        </div>
      </nav>
    </>
  );
}

export default Nav;
