import config from "../../exiry.config";

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageView = (url: URL, pageTitle = "") => {
  const params:
    | Gtag.ConfigParams
    | Gtag.EventParams
    | Gtag.ControlParams
    | Gtag.CustomParams = {
    page_path: url.toString(),
  };
  if (pageTitle) {
    params.page_title = pageTitle;
  }
  if (!config.GA_TrackingId || process.env.NODE_ENV !== "production") {
    return;
  }
  if (typeof window.gtag !== "function") {
    return;
  }
  window.gtag("config", config.GA_TrackingId, params);
};

export interface GTagEvent {
  action: Gtag.EventNames | string;
  category: string;
  label: string;
  value: number;
  nonInteraction?: boolean;
}

export const event = (gTagEvent: GTagEvent) => {
  if (!config.GA_TrackingId || process.env.NODE_ENV !== "production") {
    return;
  }
  if (typeof window.gtag !== "function") {
    return;
  }
  const { action, category, label, value } = gTagEvent;
  const params: Gtag.EventParams | Gtag.ControlParams | Gtag.CustomParams = {
    event_category: category,
    event_label: label,
    value: value,
  };
  if (typeof gTagEvent.nonInteraction === "boolean") {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    params.non_interaction = gTagEvent.nonInteraction;
  }
  window.gtag("event", action, params);
};
