import React, { useState, useEffect, SetStateAction, Dispatch } from "react";
import classes from "./NavItem.module.scss";
import { useRouter } from "next/router";
import clsx from "clsx";
import { MenuItem } from "../../config/navigation";

interface NavItem {
  item: MenuItem;
  setClick: Dispatch<SetStateAction<boolean>>;
}

export default function NavItem(props: NavItem) {
  const { item, setClick } = props;

  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const href = item.url.replace(
    /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/,
    ""
  );
  const selected = router.pathname === href || router.pathname + "/" === href;
  const hasChildren = item?.childItems?.length > 0;

  useEffect(() => {
    const handleStart = (url: string) => url !== href && setLoading(false);
    router.events.on("routeChangeStart", handleStart);
    return () => {
      router.events.off("routeChangeStart", handleStart);
    };
  });

  const handleClick = (e) => {
    e.preventDefault();
    setLoading(true);
    router.push(href);
    setClick(false);
  };

  const handleChildClick = (e, href) => {
    e.preventDefault();
    setLoading(true);
    router.push(href);
    setClick(false);
  };

  return (
    <>
      <div
        className={clsx(classes.link)}
        id={item.id ? "nav-item-" + item.id : ""}
      >
        <a
          href={href}
          aria-label={item.label}
          className={clsx(classes.link, { loading, active: selected })}
          onClick={handleClick}
        >
          {item.label}
          {hasChildren && <i className={classes["arrow-down"]}></i>}
        </a>
        {hasChildren && (
          <div className={classes["dropdown-content"]}>
            {item.childItems.map((child, i) => (
              <a
                href={child.url}
                key={i}
                aria-label={child.label || ""}
                onClick={(event) => handleChildClick(event, child.url)}
              >
                {child.label}
              </a>
            ))}
          </div>
        )}
      </div>
    </>
  );
}
