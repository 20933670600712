import data from "../../json/data.json";
export interface MenuItem {
  label: string;
  url: string;
  id: string;
  childItems?: MenuItem[];
}

const years = Object.keys(data).sort().reverse();

export const mainMenu: MenuItem[] = [
  {
    label: "Accueil",
    url: "/",
    id: "accueil",
  },
  {
    label: "Lauréats",
    url: "",
    id: "laureats",
    childItems: years.map((year) => ({
      label: `Année ${year}`,
      url: `/laureats/annee-${year}`,
      id: `laureats-annee-${year}`,
    })),
  },
  {
    label: "Vidéothèque",
    url: "/videotheque",
    id: "videotheque",
  },
  {
    label: "Contact",
    url: "/contact",
    id: "contact",
  },
];

export const dropdownMenu: MenuItem[] = [
  {
    label: "2018",
    url: "2018",
    id: "2018",
  },
  {
    label: "2017",
    url: "2017",
    id: "2017",
  },
  {
    label: "2016",
    url: "2016",
    id: "2016",
  },
  {
    label: "2015",
    url: "2015",
    id: "2015",
  },
  {
    label: "2014",
    url: "2014",
    id: "2014",
  },
  {
    label: "2013",
    url: "2013",
    id: "2013",
  },
  {
    label: "2012",
    url: "2012",
    id: "2012",
  },
  {
    label: "2010",
    url: "2010",
    id: "2010",
  },
  {
    label: "2009",
    url: "2009",
    id: "2009",
  },
  {
    label: "2008",
    url: "2008",
    id: "2008",
  },
];
export const navigation = [...dropdownMenu, ...mainMenu];
