import {
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  ReactNode,
} from "react";
import { gql, useMutation } from "@apollo/client";
import { loginDataFields } from "../query/login";
import { useEffect } from "react";
import Cookies from "universal-cookie";
import AuthModal from "@app/components/AuthModals/AuthModal";

const cookies = new Cookies();

interface LoginData {
  token: string;
  user: User;
}

interface Role {
  id: string;
  name: string;
}

interface User {
  id: string;
  firstName: string;
  lastName: string;
  civility: "MR" | "MS";
  birthday: string;
  training: string;
  title: string;
  email: string;
  phoneNumber: string;
  isEmailValid: boolean;
  role?: Role;
}

type ModalTab = "login" | "sign-up" | "forget-password";

interface AuthContext {
  user: User | null;
  setUser: Dispatch<SetStateAction<User | null>>;
  modelTab: ModalTab | null;
  setModalState: Dispatch<SetStateAction<ModalTab | null>>;
  token: string;
  setLocalToken: (token?: string) => void;
  logout: () => void;
  loading: boolean;
  isChoseUserTypeModalOpen: boolean;
  setChoseUserTypeModalOpen: Dispatch<SetStateAction<boolean>>;
  handleLoginData: (data: LoginData) => void;
}

interface UseAuthContext extends AuthContext {
  authHeader: () => {
    authorization?: string;
  };
  handleLogin: (token: string, user: User) => void;
  role: "administrator" | "candidate" | "jury" | "jury-president";
}

interface AuthContextProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<AuthContext | null>(null);

const LOGGED_USER_QUERY = gql`
  ${loginDataFields}
  mutation me {
    loginWithToken {
      ...LoginDataFields
      __typename
    }
  }
`;

export function AuthContextProvider({ children }: AuthContextProviderProps) {
  const [signInWithToken, { loading }] = useMutation(LOGGED_USER_QUERY);

  const [user, setUser] = useState<User | null>(null);
  const [token, setToken] = useState<string>("");
  const [modelTab, setModalState] = useState<ModalTab | null>(null);
  const [isChoseUserTypeModalOpen, setChoseUserTypeModalOpen] = useState(false);

  const setLocalToken = (_token?: string) => {
    if (_token) {
      cookies.set("tkr_auth_token", _token, { path: "/" });
      if (_token !== token) {
        setToken(_token);
      }
    } else {
      cookies.set("tkr_auth_token", "", {
        expires: new Date(0),
        path: "/",
      });
    }
  };

  const logout = () => {
    setLocalToken();
    window.location.href = "/";
    setUser(null);
    setToken("");
  };

  const handleLoginData = (loginData: LoginData) => {
    if (loginData) {
      setLocalToken(loginData.token);
      setUser(loginData.user);
    }
  };

  useEffect(() => {
    const localToken =
      typeof window !== "undefined" && cookies.get("tkr_auth_token");
    if (localToken) {
      signInWithToken()
        .then(({ data }) => {
          handleLoginData(data?.loginWithToken || null);
        })
        .catch((error) => {
          if (process.env.NODE_ENV !== "production") {
            console.error(error);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        modelTab,
        setModalState,
        token,
        setLocalToken,
        logout,
        loading,
        isChoseUserTypeModalOpen,
        setChoseUserTypeModalOpen,
        handleLoginData,
      }}
    >
      {children}
      <AuthModal />
    </AuthContext.Provider>
  );
}

export function useAuthContext(): UseAuthContext {
  const state = useContext(AuthContext);

  if (!state) {
    throw new Error("useAuthContext must be used within a AuthContextProvider");
  }

  const authHeader = () => {
    // Get the token from the client side
    let token = null;
    if (typeof window !== "undefined") {
      token = cookies.get("tkr_auth_token") || null;
    }
    if (!token) return {};
    return {
      authorization: token ? `Bearer ${token}` : "",
    };
  };

  const handleLogin = (token: string, user: User) => {
    state.setUser(user);
    state.setLocalToken(token);
    state.setModalState(null);
  };

  const role = (state.user?.role?.name || "") as UseAuthContext["role"];

  return { ...state, authHeader, handleLogin, role };
}

export default AuthContext;
