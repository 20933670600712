import { gql } from "@apollo/client";

export const AuthPayload = gql`
  fragment AuthPayload on User {
    id
    firstName
    lastName
    civility
    birthday
    training
    title
    phoneNumber
    email
    isEmailValid
    role {
      id
      name
    }
  }
`;

export const loginDataFields = gql`
  ${AuthPayload}
  fragment LoginDataFields on LoginData {
    token
    user {
      ...AuthPayload
    }
  }
`;

export const MeQuery = gql`
  query {
    me {
      id
      role {
        id
        name
      }
    }
  }
`;
