import classes from "./Button.module.scss";
import clsx from "clsx";
import Link from "next/link";
import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  DetailedHTMLProps,
} from "react";

type AnchorProps = DetailedHTMLProps<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>;

type ButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

interface JamyButtonProps {
  variant?: "outlined" | "black";
  fullWidth?: boolean;
  loading?: boolean;
  disabled?: boolean;
}

interface ButtonPropsAnchor extends JamyButtonProps, AnchorProps {
  href: string;
}

interface ButtonPropsButton extends JamyButtonProps, ButtonProps {
  type?: "submit" | "reset" | "button";
}

export default function Button(props: ButtonPropsAnchor | ButtonPropsButton) {
  const { loading, disabled, fullWidth, onClick, ...others } = props;

  const renderProps: ButtonProps | AnchorProps = {
    ...others,
    className: clsx(
      props.className || "",
      classes["root"],
      {
        loading,
        disabled,
        outlined: props.variant === "outlined",
        black: props.variant === "black",
        [props.color]: !!props.color,
      },
      { [classes["full-width"]]: fullWidth }
    ),
  };

  if (!disabled && typeof onClick === "function") {
    renderProps.onClick = onClick;
  }

  if ((props as ButtonPropsAnchor).href)
    return (
      <Link href={(props as ButtonPropsAnchor).href}>
        <a {...(renderProps as AnchorProps)}>
          {loading && <div className={classes["loader"]}></div>}
          {props.children}
          {loading && <>&nbsp;&nbsp;</>}
        </a>
      </Link>
    );

  return (
    <button {...(renderProps as ButtonProps)}>
      {loading && <div className={classes["loader"]}></div>}
      {props.children}
      {loading && <>&nbsp;&nbsp;</>}
    </button>
  );
}
