import { Menu } from "@mantine/core";
import { NextLink } from "@mantine/next";
import { ReactNode } from "react";
import { MdPerson } from "react-icons/md";
import { useAuthContext } from "../../context/AuthContext";

interface MenuItemProps {
  href?: string;
  onClick?: () => void;
  children: ReactNode;
}

const menus = {
  administrator: [
    { href: "/admin/applications", label: "Les candidatures" },
    { href: "/admin/jurys", label: "Les jurys" },
    { href: "/admin/add-jury", label: "Ajouter un jury" },
  ],
  candidate: [
    { href: "/user/my-company", label: "Mon entreprise" },
    { href: "/user/my-application", label: "Mon candidature" },
    { href: "/user/my-profile", label: "Mon profile" },
  ],
  jury: [{ href: "/jury/answers/", label: "Les candidatures" }],
  "jury-president": [
    { href: "/jury-president/answers", label: "Les candidatures" },
    { href: "/jury-president/jurys", label: "Les jurys" },
  ],
};

function MenuItem(props: MenuItemProps) {
  if (props.href) {
    return (
      <Menu.Item onClick={props.onClick} component={NextLink} href={props.href}>
        {props.children}
      </Menu.Item>
    );
  }

  return <Menu.Item onClick={props.onClick}>{props.children}</Menu.Item>;
}

export default function UserMenuButton() {
  const { setModalState, user, loading, logout, role } = useAuthContext();

  if (loading)
    return (
      <div className="flex w-[35px] h-[35px] justify-center items-center text-white bg-black rounded-sm bg-opacity-20">
        ...
      </div>
    );

  if (!user) {
    return (
      <div
        role="button"
        onClick={() => setModalState("login")}
        className="flex w-[35px] h-[35px] justify-center items-center bg-[#e8f8f7] hover:bg-accent text-accent hover:text-white/80 transition-colors rounded-full focus:outline-none"
      >
        <MdPerson size="1.4rem" aria-hidden="true" />
      </div>
    );
  }

  let menu = menus.candidate;
  if (user.role && menus[role]) {
    menu = menus[role];
  }

  return (
    <Menu>
      <Menu.Target aria-label="menu utilisateur">
        <div className="flex w-[35px] h-[35px] justify-center items-center bg-[#e8f8f7] hover:bg-accent text-accent hover:text-white/80 transition-colors rounded-full focus:outline-none">
          <MdPerson size="1.4rem" aria-hidden="true" />
        </div>
      </Menu.Target>
      <Menu.Dropdown>
        {menu.map((item) => {
          return (
            <MenuItem href={item.href} key={item.href}>
              {item.label}
            </MenuItem>
          );
        })}
        <MenuItem onClick={() => logout()}>Se déconnecter</MenuItem>
      </Menu.Dropdown>
    </Menu>
  );
}
