import Link from "next/link";

function FooterSection() {
  return (
    <footer className="bg-black">
      <div className="container  text-white">
        <h2 className="sr-only">Le bas de la page</h2>
        <div className="text-center py-6">
          <p className="text-white text-xs font-bold">
            Made proudly in Tunisia by{" "}
            <Link href="https://tekru.net">
              <a className="hover:text-yellow-tekru transition">
                Tekru Technologies
              </a>
            </Link>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default FooterSection;
