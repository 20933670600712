import { useAuthContext } from "../../context/AuthContext";
import { Modal } from "@mantine/core";
import ForgetPasswordForm from "./ForgetPasswordForm";
import LoginForm from "./LoginForm";
// import SignUpForm from "./SignUpForm";

export default function UserEntryModal() {
  const { modelTab, setModalState } = useAuthContext();
  const closeModal = () => setModalState(null);

  let Component: () => JSX.Element = null,
    title = "";
  title = "";
  if (modelTab === "login") {
    title = "Se connecter";
    Component = LoginForm;
    // } else if (modelTab === "sign-up") {
    //   title = "";
    //   Component = SignUpForm;
  } else if (modelTab === "forget-password") {
    title = "Mot de passe oublié";
    Component = ForgetPasswordForm;
  }

  return (
    <Modal opened={!!modelTab} title={title} onClose={closeModal}>
      {Component ? <Component /> : ""}
    </Modal>
  );
}
