import { useState, useEffect } from "react";
import Link from "next/link";
import NavigationMenu from "../../components/Nav";
import Logo from "../../components/Logo";
import clsx from "clsx";
import classes from "./Header.module.scss";
import Button from "../../components/Button";
import { useRouter } from "next/router";
import UserMenuButton from "@app/components/UserMenu/Button";
import { useAuthContext } from "src/context/AuthContext";

interface PageHeaderSectionProps {
  isOpen: boolean;
}

function PageHeaderSection(props: PageHeaderSectionProps) {
  const { user } = useAuthContext();

  const [scrolled, setScrolled] = useState(false);
  const [isScrolled, setPageScrolled] = useState(false);

  const router = useRouter();

  const href = "/estimer-mon-besoin/";
  const isHomePage = "/" === router.asPath;
  const isTargetPage = href === router.asPath;

  const changeVisibility = () => {
    const pageScrolled = window.scrollY >= 500;
    setPageScrolled(pageScrolled);
  };

  useEffect(() => {
    window.addEventListener("scroll", changeVisibility);
    const handleScroll = () => {
      if (window.pageYOffset > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", changeVisibility);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={clsx(
        "w-full sticky top-0 z-10 bg-white/75 shadow-[0_4px_30px_rgb(0,0,0,0.05)] backdrop-blur-[9px]",
        classes.root,
        { scrolled }
      )}
    >
      <div className="container">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-between w-full md:justify-start md:w-auto">
            <div className="w-64 md:w-36">
              <Link href="/">
                <a className="items-center block w-[85px] xl:w-[112px]">
                  <Logo priority={true} width={160} height={60} />
                </a>
              </Link>
            </div>
            <NavigationMenu />
          </div>

          {props.isOpen && !user && (
            <div
              className={clsx("transition-all duration-300 hidden md:block", {
                "opacity-0": (isHomePage && !isScrolled) || isTargetPage,
                "opacity-100": isHomePage && isScrolled && !isTargetPage,
              })}
            >
              <Button href="/#sign-up" className="py-6 px-8 text-center">
                <span className="font-light text-xs leading-0">
                  Tentez votre chance <br />
                  <span className="font-extrabold text-base">
                    Soumissionnez
                  </span>
                </span>
              </Button>
            </div>
          )}
          <div className="flex items-center gap-2">
            <UserMenuButton />
          </div>
        </div>
      </div>
    </header>
  );
}

export default PageHeaderSection;
