import Header from "./Header";
import Footer from "./Footer";
import { ReactNode } from "react";

interface MainLayoutProps {
  children: ReactNode;
  pageProps: object;
}

function MainLayout({ children, pageProps }: MainLayoutProps) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const isOpen = pageProps?.submissionStatus?.isOpen ?? true;

  return (
    <>
      <Header isOpen={isOpen} />
      <main className="">{children}</main>
      <Footer />
    </>
  );
}

export default MainLayout;
