import { useState } from "react";
import { useAuthContext } from "../../context/AuthContext";
import { useMutation, gql } from "@apollo/client";
import urlJoin from "url-join";
import { useForm } from "@mantine/form";
import { Button, TextInput } from "@mantine/core";

const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($email: String!, $resetBaseUrl: String) {
    resetPassword(email: $email, resetBaseUrl: $resetBaseUrl)
  }
`;

export default function ForgetPasswordForm() {
  const { setModalState } = useAuthContext();

  const [sent, setSent] = useState(false);
  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD_MUTATION);

  const form = useForm({
    initialValues: {
      email: "",
    },
    validate: {
      email: (value) =>
        /^\S+@\S+$/.test(value) ? null : "L'adresse email n'est pas valide",
    },
  });

  function handleSubmit(model: typeof form.values) {
    // Trim all white space from the email input
    const email = model.email.replace(/\s/g, "");
    // Check for link
    if (!process.env.NEXT_PUBLIC_PUBLIC_URL) return;
    // Generate the link
    const resetBaseUrl = urlJoin(
      process.env.NEXT_PUBLIC_PUBLIC_URL,
      "/user/reset-password/"
    );
    resetPassword({ variables: { email, resetBaseUrl } })
      .then(({ data }) => {
        const { resetPassword } = data;
        if (resetPassword) {
          setSent(true);
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          console.error(error);
        }
        // Handel backend errors
        form.setErrors({
          password:
            "Une erreur s'est produite lors du traitement de votre demande.",
        });
      });
  }

  return (
    <form
      onSubmit={form.onSubmit((model) => handleSubmit(model))}
      className="w-full flex flex-col gap-3"
    >
      {!sent ? (
        <>
          <TextInput
            label="Email"
            name="email"
            type="email"
            required
            disabled={loading}
            autoComplete="email"
            className="w-full"
            {...form.getInputProps("email")}
          />
          <div className="w-full flex flex-row gap-2 mt-2 justify-between items-center">
            <Button loading={loading}>Reinitialiser mon mot de passe</Button>
          </div>
        </>
      ) : (
        <>
          Un email est envoyé à votre adresse email avec les étapes à suivre
          pour réinitialiser votre mot de passe
        </>
      )}
      <hr className="mt-4 mb-2 w-[60%] mx-auto" />
      <div className="w-full items-center text-sm text-center">
        <a
          onClick={() => setModalState("login")}
          href="#"
          className="block hover:underline text-sm"
        >
          Retourner à la page de connexion
        </a>
      </div>
    </form>
  );
}
