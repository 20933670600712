import Image, { ImageProps } from "next/future/image";

interface LogoProps extends Partial<ImageProps> {
  width: number;
  height: number;
}

export default function Logo(props: LogoProps) {
  const { width, height } = props;
  return (
    <Image
      {...props}
      src="/assets/images/logo.png"
      alt={"Logo CDJ Business awards"}
      width={width}
      height={height}
    />
  );
}
